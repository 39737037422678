import React from "react";
import Modal from "components/Modal";
import moment from "moment";
import { NavLink } from "react-router-dom";
import swal from "sweetalert2";
import Scrollbar from "react-scrollbars-custom";
import {
  smoothScroll,
  formatNumber,
  img,
  commingSoonFnc,
  inGame,
  ReplaceNewString,
} from "components/common";
import ReactTooltip from "react-tooltip";
import lodash from "lodash";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      history: null,
      reward_list_spin: null,
      reward_list_ex: null,
      reward_list_sp: null,
      weekRewards: [],
      monthRewards: [],
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.parentProp.home.history != null &&
      !nextProps.parentProp.home.loading
    ) {
      this.setState({
        history: nextProps.parentProp.home.history,
      });
    }
    if (
      nextProps.parentProp.home.reward_list_spin != null &&
      !nextProps.parentProp.home.loading
    ) {
      this.setState({
        reward_list_spin: nextProps.parentProp.home.reward_list_spin,
      });
    }
    // if (nextProps.parentProp.home.reward_list_ex != null && !nextProps.parentProp.home.loading) {
    //   this.setState({
    //     reward_list_ex: nextProps.parentProp.home.reward_list_ex
    //   })
    // }
    // if (nextProps.parentProp.home.reward_list_sp != null && !nextProps.parentProp.home.loading) {
    //   this.setState({
    //     reward_list_sp: nextProps.parentProp.home.reward_list_sp
    //   })
    // }
    if (
      nextProps.parentProp.home.all_rewards != null &&
      !nextProps.parentProp.home.loading
    ) {
      this.setState({
        weekRewards: lodash.sortBy(
          nextProps.parentProp.home.all_rewards.filter(
            (item) => item.event == 5
          ),
          "sub_event"
        ),
        monthRewards: lodash.sortBy(
          nextProps.parentProp.home.all_rewards.filter(
            (item) => item.event == 6
          ),
          "sub_event"
        ),
        reward_list_ex: lodash.sortBy(
          nextProps.parentProp.home.all_rewards.filter(
            (item) => item.event == 2
          ),
          "hurdle"
        ),
        reward_list_sp: lodash.sortBy(
          nextProps.parentProp.home.all_rewards.filter(
            (item) => item.event == 3
          ),
          "hurdle"
        ),
      });
    }
  }

  handleClosePopup() {
    this.setState({ modal: null });
    $("body").removeClass("modal-open");
  }

  handleOpenPopup(modal) {
    this.setState({ modal: modal });
    $("body").addClass("modal-open");
  }

  commingSoon() {
    // if (isStartEvent == 0) {
    //   commingSoonFnc()
    //   return
    // }
    window.location.href = "/user/login";
  }

  reloadFC() {
    this.props.parentProp.reloadFC();
  }

  handleViewHistory() {
    if (!this.props.parentProp.home.login) {
      swal({
        title: "Thông báo",
        html: '<p class="pop-content">Bạn cần đăng nhập để tham gia sự kiện.</p>',
        confirmButtonText: "Đóng",
        animation: false,
        customClass: "custom-modal animated zoomIn",
      }).then((result) => {
        window.location.href = "/user/login";
      });
      return;
    }
    this.props.parentProp.getHistory();
    this.handleOpenPopup("history");
  }

  mapEvenStatus(status) {
    if (!status) {
      return;
    }
    let eventStatus;
    switch (status) {
      case 1:
        eventStatus = "Đang xử lý";
        break;
      case 2:
        eventStatus = "Đã gửi";
        break;
      case 3:
        eventStatus = "Đang xử lý";
        break;
      case 4:
        eventStatus = "Đang xử lý";
        break;
      case 5:
        eventStatus = "Đang xử lý";
        break;
      default:
        break;
    }
    return eventStatus;
  }

  getMilestones(list_gift) {
    const giftsArr = list_gift.reduce((prev, cur) => {
      prev[cur.hurdle] = [...(prev[cur.hurdle] || []), cur];
      return prev;
    }, {});

    return Object.entries(giftsArr).map((m) => {
      return {
        hurdle: +m[0],
        select: m[1].length >= 2,
        selectRewardCount: m[1].length,
        rewards: [...m[1]],
      };
    });
  }

  renderRewardNameToolTip(reward) {
    let text = `${reward.item_name}.`;
    if (reward.special_count > 0) {
      text += ` Giới hạn: ${reward.special_count}.`;
    }
    if (reward.round_required > 0) {
      text += ` Quà đặc biệt có thể chọn sau ${reward.round_required} lượt.`;
    }
    return text;
  }

  render() {
    let { layout } = this.props;
    const { modal, history, reward_list_spin, reward_list_ex, reward_list_sp } =
      this.state;
    return (
      <React.Fragment>
        <header>
          <ul className="menu">
            <li className="logo">
              {inGame != null && inGame != undefined && inGame != "" ? (
                <a href="javascript:void(0)">
                  <img src="images/logo.png" alt="" />
                </a>
              ) : (
                <a href="https://fo4.garena.vn/">
                  <img src="images/logo.png" alt="" />
                </a>
              )}
            </li>
            <li className="btn-hd">
              <a
                href="javascript:void(0)"
                onClick={(e) => this.handleOpenPopup("pop-rule")}
              >
                hướng dẫn
              </a>
            </li>
            <li className="line"></li>
            <li className="btn-ls">
              <a
                href="javascript:void(0)"
                onClick={() => this.handleViewHistory()}
              >
                lịch sử
              </a>
            </li>
            <li className="line"></li>
            <li className="btn-gift">
              <a
                href="javascript:void(0)"
                onClick={(e) => this.handleOpenPopup("pop-gift")}
              >
                danh sách quà
              </a>
            </li>
            <li className="action-l-group">
              <div className="wrap-content">
                {!this.props.currentUser.login ? (
                  <React.Fragment>
                    <a
                      className="action"
                      href="javascript:void(0)"
                      onClick={(e) => this.commingSoon()}
                    >
                      <span>ĐĂNG NHẬP</span>
                    </a>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="txt-user">
                      <span>Hi </span>{" "}
                      <b> {this.props.currentUser.currentUser.user.nickname}</b>
                    </span>
                    {inGame != 1 && (
                      <a className="action buttonSpin" href="/user/logout">
                        <span> &nbsp;&nbsp;&nbsp;đăng xuất</span>
                      </a>
                    )}
                  </React.Fragment>
                )}
              </div>
            </li>
          </ul>
        </header>
        <Modal
          show={modal === "pop-gift"}
          customClass={"pop-gift"}
          closeHandler={() => this.handleClosePopup()}
        >
          <h3 className="md-header">
            <span>danh sách quà</span>
          </h3>
          <div className="wrap">
            <div className="gift-spin">
              <h5 className="title">Quà khi chơi</h5>
              <div className="list-gift">
                <ul>
                  {reward_list_spin && (
                    <React.Fragment>
                      {reward_list_spin.map((reward, index) => (
                        <li
                          key={index}
                          data-tip={reward.item_name}
                          data-for={`tool-pop-gift`}
                        >
                          <div
                            className={`gift-box ${
                              reward.is_special == 1 ? "special-box" : ""
                            }`}
                          >
                            <img src={reward.item_url} alt={reward.item_name} />
                          </div>
                          <p className="point">{reward.item_name}</p>
                        </li>
                      ))}
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
            <div className="gift-ex">
              <h5 className="title">Quà tích lũy</h5>
              <div className="list-gift">
                <ul>
                  {reward_list_ex && reward_list_sp && (
                    <React.Fragment>
                      {this.getMilestones(
                        lodash.sortBy(
                          [...reward_list_ex, ...reward_list_sp],
                          (o) => o.hurdle
                        )
                      ).map((milestone, index) => (
                        <li
                          key={index}
                          className={`${milestone.select ? "is-select" : ""}`}
                        >
                          {!milestone.select &&
                            milestone.rewards[0].special_count > 0 && (
                              <div className="gift-limit">
                                {img("images/only-1-time.png")}
                              </div>
                            )}
                          <div
                            className={`gift-box-${milestone.selectRewardCount}`}
                          >
                            {milestone.rewards.map((reward, x) => (
                              <img
                                key={x}
                                src={reward.item_url}
                                alt={reward.item_name}
                                data-tip={this.renderRewardNameToolTip(reward)}
                                data-for="tool-pop-gift"
                              />
                            ))}
                          </div>
                          <div className="count-point">
                            {milestone.hurdle} lượt <br />
                            {milestone.select &&
                              `(Chọn 1 trong ${milestone.selectRewardCount})`}
                          </div>
                        </li>
                      ))}
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {reward_list_ex && reward_list_sp && (
            <ReactTooltip id="tool-pop-gift" />
          )}
        </Modal>
        <Modal
          show={modal === "pop-rule"}
          customClass={"modal-guide"}
          closeHandler={() => this.handleClosePopup()}
        >
          <img
            src="https://cdn.vn.garenanow.com//fo3vn/project/playerPack/SCRLT72024HD.png"
            alt=""
          />
          {/*<img src="/images/huong-dan-balldrop.png" alt=""/>*/}
        </Modal>
        <Modal
          show={modal === "history"}
          customClass={"pop-history"}
          closeHandler={() => this.handleClosePopup()}
        >
          <h3 className="md-header">
            <span>lịch sử</span>
          </h3>
          <div className="fire-success">
            <div className="pop-body-history">
              <Scrollbar
                className={`top-all-content`}
                translateContentSizesToHolder={true}
              >
                <table className="tbl-history">
                  <thead>
                    <tr>
                      <th>stt</th>
                      <th>tên vật phẩm</th>
                      <th>thời gian</th>
                      <th>trạng thái</th>
                      <th>Sự kiện</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history &&
                      history.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.special_type == 1 && (
                              <span>{item.item_name}</span>
                            )}
                            {item.special_type == 2 && (
                              <span className="special-text">
                                {item.item_name}{" "}
                                <span className="special-text-x2"> (X2)</span>
                              </span>
                            )}
                            {item.special_type == 3 && (
                              <span className="special-text">
                                {item.item_name}
                                <span className="special-text-x2">
                                  {" "}
                                  (THÊM LƯỢT)
                                </span>
                              </span>
                            )}
                          </td>
                          <td>{item.create_time}</td>
                          <td>{this.mapEvenStatus(item.status)}</td>
                          <td>
                            {item.event == 2 || item.event == 3 ? (
                              <React.Fragment>
                                <span>Quà tích lũy</span>
                                {item.event == 3 && (
                                  <React.Fragment>
                                    <span> (Quà chọn)</span>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {item.is_free == 1 ? (
                                  <span>Vòng quay Miễn Phí</span>
                                ) : (
                                  item.event == 1 && (
                                    <React.Fragment>
                                      {item.payment_type == 1 ? (
                                        <span>Vòng quay FC</span>
                                      ) : (
                                        <span>Vòng quay MC</span>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Scrollbar>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
