import request from "lib/request"
import swal from "sweetalert2"
import {showErrorWithCode} from 'components/common'

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING = 'CURRENT_USER_FETCHING'
export const IS_GET_API = 'IS_GET_API'
export const GET_LEVEL_INFO_SUCCESS = 'GET_LEVEL_INFO_SUCCESS'
export const GET_MISSION_SUCCESS = 'GET_MISSION_SUCCESS'
export const GET_SPIN_REWARD_SUCCESS = 'GET_SPIN_REWARD_SUCCESS'
export const GET_MB_REWARD_SUCCESS = 'GET_MB_REWARD_SUCCESS'
export const SPIN_LUCKY_SUCCESS = 'SPIN_LUCKY_SUCCESS'
export const CHECK_MISSION_SUCCESS = 'CHECK_MISSION_SUCCESS'
export const GET_EXCHANGE_SUCCESS = 'GET_EXCHANGE_SUCCESS'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const RELOAD_FC_SUCCESS = 'RELOAD_FC_SUCCESS'
export const GET_SP_REWARD_SUCCESS = 'GET_SP_REWARD_SUCCESS'
export const EX_SP_SUCCESS = 'EX_SP_SUCCESS'
export const RESET_HURDLE_SUCCESS = 'RESET_HURDLE_SUCCESS'

export const LISTEN_UPDATE_LOTTERY_RESULT = 'LISTEN_UPDATE_LOTTERY_RESULT';
export const isGetApi = () => {
  return {
    type: IS_GET_API
  }
}
//user
export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}
export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
        if (!response.payload.user.account_id) {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          }).then((result) => {
            window.location.href = "/user/logout"
          })
        }
      } else {
        dispatch(getCurrentUserError(response))
        if (response.error_code == 'error_account_not_found') {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          }).then((result) => {
            window.location.href = "/user/logout"
          })
        }
      }
    })
  }
}
export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}
export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response
  }
}
//get level
export const getMission = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/mission/receive').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getMissionSuccess(response));
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">Nhận nhiệm vụ thành công.</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      } else {
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      }
    })
  }
}
export const getMissionSuccess = (response) => {
  return {
    type: GET_MISSION_SUCCESS,
    payload: response.payload
  }
}
//check mission
export const checkMission = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/mission/update_mission_status').then(function (response) {
      if (response.status == 'successful') {
        dispatch(checkMissionSuccess(response));
        if (response.payload.user_mission_infos[0].status == 1) {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa hoàn thành nhiệm vụ, vui lòng hoàn thành và thử lại sau.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          })
        } else if (response.payload.user_mission_infos[0].status == 2) {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn đã hoàn thành nhiệm vụ.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          })
        } else {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Cập nhật tiến độ nhiệm vụ thành công.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          })
        }
      } else {
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      }
    })
  }
}
export const checkMissionSuccess = (response) => {
  return {
    type: CHECK_MISSION_SUCCESS,
    payload: response.payload
  }
}
//get spin reward
export const getEventReward = (event) => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/reward/get_rewards_by_event?event=' + event).then(function (response) {
      if (response.status == 'successful') {
        if (event == 1) {
          dispatch(getSpinRewardSuccess(response));
        } else if (event == 2) {
          dispatch(getMBRewardSuccess(response));
        } else if (event == 3) {
          dispatch(getSPRewardSuccess(response));
        } else if (event == 0) {
          dispatch(getSPRewardSuccess(response));
        }
      } else {
      }
    })
  }
}
export const getSpinRewardSuccess = (response) => {
  return {
    type: GET_SPIN_REWARD_SUCCESS,
    payload: response.payload
  }
}
export const getMBRewardSuccess = (response) => {
  return {
    type: GET_MB_REWARD_SUCCESS,
    payload: response.payload
  }
}
export const getSPRewardSuccess = (response) => {
  return {
    type: GET_SP_REWARD_SUCCESS,
    payload: response.payload
  }
}
//lucky spin
export const doSpin = (is_free, spin_type, payment_type, topup_deal, spinAnimation) => {
  return async (dispatch, getState) => {
    dispatch(isGetApi())
    const response = await request('api/reward/spin', 'POST', {
      body: JSON.stringify({
        is_free: is_free,
        spin_type: spin_type,
        payment_type: payment_type,
        topup_deal: topup_deal
      })
    })
    if (response.status == 'successful') {
      await spinAnimation(response.payload)
      dispatch(doSpinSuccess(response))
    } else {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
      $('.buttonSpin').removeClass('prevent-click')
    }
  }
}
export const doSpinSuccess = (response) => {
  return {
    type: SPIN_LUCKY_SUCCESS,
    payload: response.payload
  }
}
//get exchange shop
export const getExchangeReward = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/user/get_exchange_reward_status').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getExchangeRewardSuccess(response));
      } else {
      }
    })
  }
}
export const getExchangeRewardSuccess = (response) => {
  return {
    type: GET_EXCHANGE_SUCCESS,
    payload: response.payload
  }
}
//get history
export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/user/get_reward_infos').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getHistoryRewardSuccess(response));
      } else {
      }
    })
  }
}
export const getHistoryRewardSuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  }
}
//reload FC
export const reloadFC = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/user/reload_fc').then(function (response) {
      if (response.status == 'successful') {
        dispatch(reloadFCSuccess(response));
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">Cập nhật thành công.</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      } else {
      }
    })
  }
}
export const reloadFCSuccess = (response) => {
  return {
    type: RELOAD_FC_SUCCESS,
    payload: response.payload
  }
}

// reset hurdle
export const resetHurdleNo = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/user/reset_hurdle_no').then(function (response) {
      if (response.status == 'successful') {
        dispatch(resetHurdleNoSuccess(response));
        swal({
          title: 'Chúc mừng',
          html: '<p class="pop-content">Bạn đã reset quà tích luỹ thành công</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        });
      } else {
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      }
    })
  }
}
export const resetHurdleNoSuccess = (response) => {
  return {
    type: RESET_HURDLE_SUCCESS,
    payload: response.payload
  }
}

//exchange special
export const exchangeSpecial = (reward_id) => {
  return async (dispatch, getState) => {
    dispatch(isGetApi())
    const response = await request('api/reward/claim_special_exchange', 'POST', {
      body: JSON.stringify({
        reward_id: reward_id,
      })
    })
    if (response.status == 'successful') {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">Đổi quà thành công. Quà sẽ được gửi vào hòm thư trong 24h</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
      dispatch(exchangeSpecialSuccess(response))
    } else {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
    }
  }
}
export const exchangeSpecialSuccess = (response) => {
  return {
    type: EX_SP_SUCCESS,
    payload: response.payload
  }
}
