import {

  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,
  GET_MISSION_SUCCESS,
  IS_GET_API,
  GET_SPIN_REWARD_SUCCESS,
  GET_MB_REWARD_SUCCESS,
  SPIN_LUCKY_SUCCESS,
  CHECK_MISSION_SUCCESS,
  GET_EXCHANGE_SUCCESS,
  GET_HISTORY_SUCCESS,
  RELOAD_FC_SUCCESS,
  GET_SP_REWARD_SUCCESS,
  EX_SP_SUCCESS,
  RESET_HURDLE_SUCCESS, LISTEN_UPDATE_LOTTERY_RESULT,
} from './actions'

const convertDateForIos = (date) => {
  var arr = date.split(/[- :]/);
  date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
  return date;
}

const initialState = {
  loading: false,
  login: false,
  user: null,
  user_status: null,
  user_mission_infos: [],
  reward_list_spin: null,
  reward_list_ex: null,
  exchange_infos: null,
  reward_list_sp: null,
  history: null,
  special_exchange_infos: null,
  special_count_infos: null,
  accumulate_loop_num: 0,
  special_reward_limit_infos: [],
  all_rewards: null,
  special_date: null,
  system_time: null,
  lottery_info: {},
  lottery_status: {},
  lottery_result: {},
  lottery_history: [],
  delta: 0,
}

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case LISTEN_UPDATE_LOTTERY_RESULT:
      return {
        ...state,
        lottery_result: action.payload,
      };
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: action.payload.user,
        user_status: action.payload.user_status,
        user_mission_infos: action.payload.user_mission_infos,
        exchange_infos: action.payload.exchange_infos,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_count_infos: action.payload.special_count_infos,
        accumulate_loop_num: action.payload.accumulate_loop_num,
        special_reward_limit_infos: action.payload.special_reward_limit_infos,
        lottery_status: action.payload.lottery_status,
        lottery_history: action.payload.lottery_history,
        lottery_result: {},
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;
    case IS_GET_API:
      return {
        ...state,
        loading: true
      }
      break;
    case GET_MISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        user_mission_infos: action.payload.user_mission_infos,
      }
      break;
    case CHECK_MISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        user_mission_infos: action.payload.user_mission_infos,
        user_status: action.payload.user_status,
      }
      break;
    case GET_SPIN_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reward_list_spin: action.payload.reward_infos,
        special_date: action.payload.special_date,
        system_time: action.payload.system_time,
        delta: new Date(convertDateForIos(action.payload.system_time)).getTime() - new Date().getTime(),
      }
      break;
    case GET_MB_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reward_list_ex: action.payload.reward_infos,
        special_date: action.payload.special_date,
        system_time: action.payload.system_time,
        delta: new Date(convertDateForIos(action.payload.system_time)).getTime() - new Date().getTime(),
      }
      break;
    case GET_SP_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        all_rewards: action.payload.reward_infos,
        reward_list_sp: action.payload.reward_infos,
        lottery_info: action.payload.lottery_info,
      }
      break;
    case GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        exchange_infos: action.payload.exchange_infos,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_count_infos: action.payload.special_count_infos,
      }
      break;
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload.reward_infos,
      }
      break;
    case SPIN_LUCKY_SUCCESS:
      return {
        ...state,
        loading: false,
        user_status: action.payload.user_status,
        exchange_infos: action.payload.exchange_infos,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_count_infos: action.payload.special_count_infos,
        special_reward_limit_infos: action.payload.special_reward_limit_infos,
        lottery_status: action.payload.lottery_status,
        user: {
          ...state.user,
          fc: action.payload.fc,
          mc: action.payload.mc,
        }
      }
      break;
    case RELOAD_FC_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          fc: action.payload.fc,
          mc: action.payload.mc,
        }
      }
      break;
    case EX_SP_SUCCESS:
      return {
        ...state,
        loading: false,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_count_infos: action.payload.special_count_infos,
        special_reward_limit_infos: action.payload.special_reward_limit_infos,
      }
      break;
    case RESET_HURDLE_SUCCESS:
      return {
        ...state,
        user_status: action.payload.user_status,
        exchange_infos: action.payload.exchange_infos,
      }
      break;
    default:
      return state
  }
}
